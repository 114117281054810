/*
 Fork of: https://github.com/backstage/backstage/blob/master/plugins/catalog-graph/src/components/EntityRelationsGraph/DefaultRenderNode.tsx
 */
import { DependencyGraphTypes } from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { EntityNodeData } from '@backstage/plugin-catalog-graph';
import { PandoraBlack, PandoraBlue, PandoraGreen, PandoraOrange, PandoraPurple, PandoraYellow } from '@internal/plugin-pandora-react';

const useStyles = makeStyles(
    theme => ({
        text: {
            fill: PandoraBlack, // theme.palette.getContrastText(theme.palette.grey[300]),

            // '&.primary': {
            //     fill: theme.palette.primary.contrastText,
            // },
            // '&.secondary': {
            //     fill: theme.palette.secondary.contrastText,
            // },
            // '&.focused': {
            //     fontWeight: 'bold',
            // },
        },
        clickable: {
            cursor: 'pointer',
        },
    }),
    { name: 'PluginCatalogGraphCustomNode' },
);


function EntityKindIcon({
    kind,
    ...props
}: {
    kind: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    className?: string;
}) {
    const app = useApp();
    const Icon =
        app.getSystemIcon(`kind:${kind.toLocaleLowerCase('en-US')}`) ?? SvgIcon;
    return <Icon {...props} />;
}


export function ApiGraphCustomNodeRenderer({
    node: { id, entity, color = 'default', focused, onClick },
}: DependencyGraphTypes.RenderNodeProps<EntityNodeData>) {
    const classes = useStyles();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const app = useApp();
    const idRef = useRef<SVGTextElement | null>(null);

    useLayoutEffect(() => {
        // set the width to the length of the ID
        if (idRef.current) {
            let { height: renderedHeight, width: renderedWidth } =
                idRef.current.getBBox();
            renderedHeight = Math.round(renderedHeight);
            renderedWidth = Math.round(renderedWidth);

            if (renderedHeight !== height || renderedWidth !== width) {
                setWidth(renderedWidth);
                setHeight(renderedHeight);
            }
        }
    }, [width, height]);

    const {
        kind,
        metadata: { name, namespace = DEFAULT_NAMESPACE, title },
    } = entity;

    const hasKindIcon = app.getSystemIcon(
        `kind:${kind.toLocaleLowerCase('en-US')}`,
    );
    const padding = 10;
    const iconSize = height;
    const paddedIconWidth = hasKindIcon ? iconSize + padding : 0;
    const paddedWidth = paddedIconWidth + width + padding * 2;
    const paddedHeight = height + padding * 2;

    const displayTitle =
        title ??
        (kind && name && namespace
            ? humanizeEntityRef({ kind, name, namespace })
            : id);

    const kindColor = (kind: string) => {
        switch (kind.toLocaleLowerCase("en-US")) {
            case "api": return PandoraBlue;
            case "component": return PandoraYellow;
            case "system": return PandoraGreen;
            case "domain": return PandoraOrange;
            default: return PandoraOrange;
        }
    }

    return (
        <g onClick={onClick} className={classNames(onClick && classes.clickable)}>
            <rect
                // className={classNames(
                //     classes.node,
                //     color === 'primary' && 'primary',
                //     color === 'secondary' && 'secondary',
                // )}
                width={paddedWidth}
                height={paddedHeight}
                rx={5}
                fill={kindColor(kind)}
                stroke={PandoraBlack}
                strokeWidth={1}
                strokeLinecap='round'
            />
            {hasKindIcon && (
                <EntityKindIcon
                    kind={kind}
                    y={padding}
                    x={padding}
                    width={iconSize}
                    height={iconSize}
                    className={classNames(
                        classes.text,
                        focused && 'focused',
                        color === 'primary' && 'primary',
                        color === 'secondary' && 'secondary',
                    )}
                />
            )}
            <text
                ref={idRef}
                className={classNames(
                    classes.text,
                    focused && 'focused',
                    color === 'primary' && 'primary',
                    color === 'secondary' && 'secondary',
                )}
                y={paddedHeight / 2}
                x={paddedIconWidth + (width + padding * 2) / 2}
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {displayTitle}
            </text>
        </g>
    );
}