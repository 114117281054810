/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    RadarRing,
    RadarQuadrant,
    RadarEntry,
    TechRadarLoaderResponse,
    TechRadarApi,
  } from '@backstage-community/plugin-tech-radar';
  
  const rings = new Array<RadarRing>();
  rings.push({
    id: 'adopt',
    name: 'ADOPT',
    color: '#929000',
    description:
      'Commodi accusantium culpa sed itaque excepturi rem eum nulla possimus.',
  });
  rings.push({
    id: 'trial',
    name: 'TRIAL',
    color: '#76D6FF',
    description: 'Recusandae possimus ipsum dolores.',
  });
  rings.push({
    id: 'assess',
    name: 'ASSESS',
    color: '#FFD479',
    description:
      'In asperiores repellat error recusandae et adipisci laborum porro.',
  });
  rings.push({
    id: 'hold',
    name: 'HOLD',
    color: '#FF7E79',
    description: 'Esse mollitia in.',
  });
  
  const quadrants = new Array<RadarQuadrant>();
  quadrants.push({ id: 'data', name: 'Data and Analytics' });
  quadrants.push({ id: 'devops', name: 'DevOps Lifecycle' });
  quadrants.push({ id: 'infrastrure', name: 'Enterprise Productivity and Automation' });
  quadrants.push({ id: 'languages', name: 'Languages, Techniques and Tools' });
  
  const entries = new Array<RadarEntry>();
  entries.push({
    timeline: [
      {
        moved: 0,
        ringId: 'adopt',
        date: new Date('2020-08-06'),
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      },
      {
        moved: -1,
        ringId: 'trial',
        date: new Date('2020-10-06'),
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      },
      {
        moved: 1,
        ringId: 'adopt',
        date: new Date('2020-12-06'),
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      },
    ],
    key: 'javascript',
    id: 'javascript',
    title: 'JavaScript',
    quadrant: 'languages',
    links: [
      {
        url: 'https://www.javascript.com/',
        title: 'Learn more',
      },
      {
        url: 'https://www.typescriptlang.org/',
        title: 'TypeScript',
      },
    ],
    description:
      'Excepteur **sint** occaecat *cupidatat* non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n```ts\nconst x = "3";\n```\n',
  });
  entries.push({
    timeline: [
      {
        moved: -1,
        ringId: 'adopt',
        date: new Date('2020-08-06'),
        description:
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
      },
    ],
    key: 'typescript',
    id: 'typescript',
    title: 'TypeScript',
    quadrant: 'languages',
    description:
      'Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat',
  });
  entries.push({
    timeline: [
      {
        moved: 1,
        ringId: 'hold',
        date: new Date('2020-08-06'),
        description:
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
      },
    ],
    links: [
      {
        url: 'https://webpack.js.org/',
        title: 'Learn more',
      },
    ],
    key: 'webpack',
    id: 'webpack',
    title: 'Webpack',
    quadrant: 'data',
  });
  entries.push({
    timeline: [
      {
        moved: 1,
        ringId: 'hold',
        date: new Date('2020-08-06'),
        description:
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
      },
    ],
    links: [
      {
        url: 'https://webpack.js.org/',
        title: 'Learn more',
      },
    ],
    key: 'React',
    id: 'React',
    title: 'React',
    quadrant: 'languages',
  });
  
  export const mock: TechRadarLoaderResponse = {
    entries,
    quadrants,
    rings,
  };
  
  export class PandoraTechRadarApi implements TechRadarApi {
    async load() {
      return mock;
    }
  }
  