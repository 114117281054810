import { default as React } from 'react';
import { Header, Page, RoutedTabs } from "@backstage/core-components";
import { AdminCatalogPage } from './AdminCatalogPage';

export const AdminPage = () => {
  return (
    <Page themeId="home">
      <Header
        title='Admin stuff'
        subtitle='All the fancy stuff happens here'
      />
      <RoutedTabs routes={[
        { title: "Full Catalog", path: "catalog", children: (<AdminCatalogPage />) },
        // { title: "API Graph", path: "graph", children: (<ApiGraphPage />) },
      ]} />
    </Page>
  );
};


