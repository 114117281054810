import { Entity } from "@backstage/catalog-model";
import { DefaultEntityFilters, EntityFilter, EntityKindFilter, EntityTypeFilter, useEntityList } from "@backstage/plugin-catalog-react";
import { FormControl, Typography, FormGroup, FormControlLabel, Checkbox, Radio, RadioGroup } from "@material-ui/core";
import React, { useState } from "react";

// class CustomKindsFilter implements EntityFilter {
//     constructor(readonly values: string[]) { }
//     filterEntity(entity: Entity): boolean {
//         const tier = entity.metadata.annotations?.['company.com/security-tier'];
//         return tier !== undefined && this.values.includes(tier);
//     }
// }
// export type CustomFilters = DefaultEntityFilters & {
//     securityTiers?: CustomKindsFilter;
// };

export const CustomKindsPicker = () => {
    // The securityTiers key is recognized due to the CustomFilter generic
    const {
        filters,
        updateFilters,
    } = useEntityList<DefaultEntityFilters>();

    const [selectedKind, setSelectedKind] = useState("system")

    // Toggles the value, depending on whether it's already selected
    function onChange(value: string) {
        setSelectedKind(value);
        switch (value) {
            case "system":
            case "component":
            case "api":
            case "group":
                filters.kind?.getCatalogFilters()
                updateFilters({ kind: new EntityKindFilter(value), type: undefined });

                break;

            case "domain":
                updateFilters({ kind: new EntityKindFilter("domain"), type: new EntityTypeFilter(["Domain"]) });
                break;

            case "productline":
                updateFilters({ kind: new EntityKindFilter("domain"), type: new EntityTypeFilter(["ProductLine", "SubDomain"]) });
                break;
            case "application":
                updateFilters({ kind: new EntityKindFilter("component"), type: new EntityTypeFilter(["Application"]) });
                break;
            case "repo":
                updateFilters({ kind: new EntityKindFilter("resource"), type: new EntityTypeFilter(["ADO Repository"]) });
                break;
        }
    }

    return (
        <FormControl component="fieldset">
            <Typography variant="button">Kinds</Typography>
            <FormGroup>
                <RadioGroup aria-label="gender" name="gender1" value={selectedKind} onChange={(_, v) => onChange(v)}>
                    <FormControlLabel value="domain" control={<Radio />} label="Domains" />
                    <FormControlLabel value="productline" control={<Radio />} label="Product lines" />
                    <FormControlLabel value="system" control={<Radio />} label="Products" />
                    <FormControlLabel value="component" control={<Radio />} label="Components" />
                    <FormControlLabel value="application" control={<Radio />} label="Applications" />
                    <FormControlLabel value="api" control={<Radio />} label="APIs" />
                    <FormControlLabel value="group" control={<Radio />} label="Product Teams" />
                    <FormControlLabel value="repo" control={<Radio />} label="Code Repositories" />
                </RadioGroup>
            </FormGroup>
        </FormControl>
    );
};