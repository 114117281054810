import {
    TableColumn,
    TableProps,
} from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
    EntityKindPicker,
    EntityLifecyclePicker,
    EntityListProvider,
    EntityOwnerPicker,
    EntityTagPicker,
    EntityTypePicker,
    UserListFilterKind,
    UserListPicker,
    CatalogFilterLayout,
    EntityOwnerPickerProps,
} from '@backstage/plugin-catalog-react';
import React from 'react';

const defaultColumns: TableColumn<CatalogTableRow>[] = [
    CatalogTable.columns.createTitleColumn({ hidden: true }),
    CatalogTable.columns.createNameColumn({ defaultKind: 'API' }),
    // CatalogTable.columns.createSystemColumn(),
    //CatalogTable.columns.createOwnerColumn(),
    CatalogTable.columns.createSpecTypeColumn(),
    CatalogTable.columns.createTagsColumn(),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createSpecLifecycleColumn(),
];

type CustomApiExplorerPageProps = {
    initiallySelectedFilter?: UserListFilterKind;
    columns?: TableColumn<CatalogTableRow>[];
    actions?: TableProps<CatalogTableRow>['actions'];
    ownerPickerMode?: EntityOwnerPickerProps['mode'];
};

export const CustomApiExplorerPage = (props: CustomApiExplorerPageProps) => {
    const {
        initiallySelectedFilter = 'all',
        columns,
        actions,
        ownerPickerMode,
    } = props;

    return (
        <EntityListProvider>
            <CatalogFilterLayout>
                <CatalogFilterLayout.Filters>
                    <EntityKindPicker initialFilter="api" hidden />
                    <EntityTypePicker />
                    <UserListPicker initialFilter={initiallySelectedFilter} />
                    <EntityOwnerPicker mode={ownerPickerMode} />
                    <EntityLifecyclePicker />
                    <EntityTagPicker />
                </CatalogFilterLayout.Filters>
                <CatalogFilterLayout.Content>
                    <CatalogTable
                        columns={columns || defaultColumns}
                        actions={actions}
                    />
                </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
        </EntityListProvider>
    );
};