import React from "react";
import { Grid } from '@material-ui/core';
import {
    EntityAboutCard,
    EntityHasSystemsCard,
    EntityLayout,
  } from '@backstage/plugin-catalog';
  import {
    Direction,
    EntityCatalogGraphCard,
  } from '@backstage/plugin-catalog-graph';
  import {
    RELATION_PARENT_OF,
  } from '@backstage/catalog-model';
    
export const BusinessCapabilityEntityLayout = () => (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.BOTTOM_TOP}
          title="Business Capability Map"
          height={300}
          relations={[
            RELATION_PARENT_OF,
          ]}
          unidirectional={false}
          maxDepth={3}
        />
          </Grid>
          <Grid item md={12}>
            <EntityHasSystemsCard variant="gridItem" title="Products supporting business capability" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );