import React, { useCallback, useEffect, useState } from "react"
import FlowiseAiChatBot from './web'
import { useApi, identityApiRef, useAnalytics, discoveryApiRef } from '@backstage/core-plugin-api'


export const ChatBot = (props: {}) => {
    var discoApi = useApi(discoveryApiRef);
    const identityApi = useApi(identityApiRef);
    const analytics = useAnalytics();

    (async () => {
        const baseUrl = await discoApi.getBaseUrl("ai");
        FlowiseAiChatBot.init({
            chatflowid: "5f5636cd-69ae-4a02-bee5-83dc599a7474",
            // chatflowid: "706cbe96-8801-4d91-ba84-bab7c016b95c",
            apiHost: baseUrl,
            chatflowConfig: {
                // topK: 2
            },
            observersConfig: {
                observeMessages: (messages: { message: string; type: string }[]) => {
                    analytics.captureEvent('chat', `Conversation stack count: ${messages.length}`, {
                        attributes: {
                            "StackCount": messages.length
                        }
                    });
                },
            },
            onRequest: async (request) => {
                const cred = await identityApi.getCredentials();
                const headers = new Headers(request.headers);
                headers.append("Authorization", `Bearer ${cred.token}`);
                request.headers = headers;
            },
            theme: {
                button: {
                    backgroundColor: "#FFCAD4",
                    right: 20,
                    bottom: 20,
                    size: 64, // small | medium | large | number
                    dragAndDrop: true,
                    iconColor: "white",
                    customIconSrc: "/helios-logo-256.png",
                },
                tooltip: {
                    showTooltip: false,
                    tooltipMessage: 'Hi There 👋!',
                    tooltipBackgroundColor: 'black',
                    tooltipTextColor: 'white',
                    tooltipFontSize: 16,
                },
                chatWindow: {
                    showTitle: true,
                    title: 'Helios Bot',
                    titleAvatarSrc: '/helios-logo-256.png',
                    showAgentMessages: false,
                    welcomeMessage: 'Hi Pandorian! What can I help you with?',
                    errorMessage: "Oh snap! Something went wrong - that's what happens with software sometimes. Please try again",
                    backgroundColor: "#ffffff",
                    height: 700,
                    width: 400,
                    fontSize: 16,
                    poweredByTextColor: "#303235",
                    botMessage: {
                        backgroundColor: "#f7f8ff",
                        textColor: "#303235",
                        showAvatar: true,
                        avatarSrc: "/helios-logo-256.png",
                    },
                    userMessage: {
                        backgroundColor: "#3B81F6",
                        textColor: "#ffffff",
                        showAvatar: true,
                        avatarSrc: "/chat-human.png",
                    },
                    textInput: {
                        placeholder: 'Type your question',
                        backgroundColor: '#ffffff',
                        textColor: '#303235',
                        sendButtonColor: '#3B81F6',
                        maxChars: 150,
                        maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 150 characters.',
                        autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
                        sendMessageSound: false,
                        // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
                        receiveMessageSound: false,
                        // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true. 
                    },
                    feedback: {
                        color: '#303235',
                    },
                    footer: {
                        textColor: '#303235',
                        text: 'Powered by',
                        company: 'Flowise',
                        companyLink: 'https://flowiseai.com',
                    }
                }
            }
        })
    })();

    return (<></>);
}