import {
  Entity
} from '@backstage/catalog-model';
/***/
/**
 * Common functionalities for the ea-pack plugin.
 *
 * @packageDocumentation
 */

export const BusinessCapabilityEntityV1beta1KindName = "BusinessCapability"
export interface BusinessCapabilityEntityV1beta1 extends Entity {
  spec: {
    type: string;
    level?: number;
    order?: number;
    parent: string;
    owner?: string;
    color?: string;
  };
}