import {
    TableColumn,
    TableProps,
} from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
    EntityKindPicker,
    EntityLifecyclePicker,
    EntityListProvider,
    EntityOwnerPicker,
    EntityTagPicker,
    EntityTypePicker,
    UserListFilterKind,
    UserListPicker,
    CatalogFilterLayout,
    EntityOwnerPickerProps,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { EntityColumCustomizer } from '../SoftwareCatalogCustomizations';

export const AdminCatalogPage = () => {

    return (
        <EntityListProvider>
            <CatalogFilterLayout>
                <CatalogFilterLayout.Filters>
                    <EntityKindPicker initialFilter='system'/>
                    <EntityTypePicker initialFilter='all' />
                    <EntityLifecyclePicker />
                    <EntityTagPicker />
                    <EntityOwnerPicker mode='all' />
                </CatalogFilterLayout.Filters>
                <CatalogFilterLayout.Content>
                    <CatalogTable
                        columns={EntityColumCustomizer}
                    />
                </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
        </EntityListProvider>
    );
};