import React, { PropsWithChildren, useEffect, useState } from "react";
import { Header, ItemCardHeader, Link, LinkButton, Page, Progress, RoutedTabs } from "@backstage/core-components";
import { SystemEntityV1alpha1 } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Box, Card, CardActions, CardContent, CardMedia, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import BusinessIcon from '@material-ui/icons/Business';
import { DomainEntityWithSubDomains } from "./ExplorePage";


function countUniqueBusinessCapabilities(products: SystemEntityV1alpha1[]): number {
  const businessCapabilitiesCount = (new Set(products.flatMap(s => s.relations?.
    filter(r => r.targetRef.startsWith("businesscapability:default/")).
    map(r => r.targetRef)))).size;
  return businessCapabilitiesCount;
}

const DomainCard = (props: { entity: DomainEntityWithSubDomains }) => {
  const { entity } = props;
  const domain = entity.domain;


  const productCount = entity.products.length;
  // Count all unique Business Capabilities supported by this Domain
  const businessCapabilitiesCount = countUniqueBusinessCapabilities(entity.products);


  return (
    <Card>
      <CardMedia>
        <ItemCardHeader>
          <Typography variant="h3">{entity.domain.metadata.title ?? domain.metadata.name}</Typography>
          <Typography variant="body1">{productCount > 0 ? `${productCount} Product${productCount != 1 ? "s" : ""} supporting ${businessCapabilitiesCount} Business Capabilities` : "No products mapped!"}</Typography>
        </ItemCardHeader>
      </CardMedia>
      <CardContent>
        <Box>
          {domain.metadata.tags?.length ? (
            domain.metadata.tags.map(tag => (
              <Chip variant="outlined" size="small" label={tag} key={tag} />
            ))
          ) : null}
        </Box>
        {domain.metadata.description}
        <List>
          {entity.subDomains.map(d => (
            <Link to={`/catalog/default/domain/${d.subDomain.metadata.name}`} key={d.subDomain.metadata.name}>
              <ListItem disableGutters>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={d.subDomain.metadata.title}
                  secondary={`${d.products.length} product${d.products.length != 1 ? "s" : ""}, ${countUniqueBusinessCapabilities(d.products)} business capabilities`} />

              </ListItem>
            </Link>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <LinkButton to={`/catalog/default/domain/${domain.metadata.name}`} color="primary">
          Explore domain
        </LinkButton>
      </CardActions>
    </Card>);
}


export const ExploreDomainPage = (props : {data: DomainEntityWithSubDomains[]}) => {
  const catalogApi = useApi(catalogApiRef);

  return (<Grid container>
    {props.data.map(d => <Grid item key={d.domain.metadata.name} xs={3}><DomainCard entity={d} /></Grid>)}
  </Grid>);
};
