import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import {
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  AboutField,
} from '@backstage/plugin-catalog';
import {
  UserEntityV1alpha1,
} from '@backstage/catalog-model';
import { useEntity, catalogApiRef } from "@backstage/plugin-catalog-react";
import { useApi } from "@backstage/core-plugin-api";
import { Avatar, Link, MarkdownContent } from "@backstage/core-components";
import { EaPackDomainEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { DesignIntegrityStatsCard } from "../DesignIntegrityStatsCard";
import { FeatureFlagged } from '@backstage/core-app-api';


const UserCard = (props: { user?: UserEntityV1alpha1 }) => {
  const { user } = props;
  return (user ? <Grid container>
    <Grid item><Avatar displayName={user.spec.profile?.displayName} picture={user.spec.profile?.picture} /></Grid>
    <Grid item>
      <Typography variant='h4'><Link to={`/catalog/${user.metadata.namespace}/user/${user.metadata.name}`}>{user.spec.profile?.displayName}</Link></Typography>
      <Typography variant='body1'>{user.spec.profile?.email}</Typography>
    </Grid>
  </Grid> : null)
}

export const SubDomainEntityLayout = () => {
  const entity = useEntity().entity as EaPackDomainEntityV1beta1;

  const catalogApi = useApi(catalogApiRef);
  const [leadUser, setLeadUser] = useState<UserEntityV1alpha1 | undefined>(undefined);
  const [coleadUser, setCoLeadUser] = useState<UserEntityV1alpha1 | undefined>(undefined);
  const [deliveryLeadUser, setDeliveryLeadUser] = useState<UserEntityV1alpha1 | undefined>(undefined);
  const [engineeringLeadUser, setEngineeringLeadUser] = useState<UserEntityV1alpha1 | undefined>(undefined);
  const [eaLeadUser, setEaLeadUser] = useState<UserEntityV1alpha1 | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const leads = entity.spec.leads;
      const uniqueNonNullUserRefs = [... new Set([leads?.lead, leads?.coLead, leads?.engineeringLead,
      leads?.deliveryLead].filter(Boolean).map(s => `user:default/${s}`))];

      const userEntities = await catalogApi.getEntitiesByRefs({ entityRefs: uniqueNonNullUserRefs });
      if (leads?.lead) {
        setLeadUser(userEntities.items.find(u => u?.metadata.name === leads.lead) as UserEntityV1alpha1)
      }
      if (leads?.coLead) {
        setCoLeadUser(userEntities.items.find(u => u?.metadata.name === leads.coLead) as UserEntityV1alpha1)
      }
      if (leads?.deliveryLead) {
        setDeliveryLeadUser(userEntities.items.find(u => u?.metadata.name === leads.deliveryLead) as UserEntityV1alpha1)
      }
      if (leads?.engineeringLead) {
        setEngineeringLeadUser(userEntities.items.find(u => u?.metadata.name === leads.engineeringLead) as UserEntityV1alpha1)
      }
      if (leads?.eaLead) {
        setEaLeadUser(userEntities.items.find(u => u?.metadata.name === leads.eaLead) as UserEntityV1alpha1)
      }

    })();
  }, [catalogApi, entity]);
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <Card >
              <CardHeader
                title="About"
              />
              <Divider />
              <CardContent >
                <Grid container>
                  <AboutField label="Description" gridSizes={{ xs: 6 }}>
                    <MarkdownContent
                      content={entity?.metadata?.description || 'No description'}
                    />
                  </AboutField>
                  <AboutField label="EA lead" gridSizes={{ xs: 6 }}>
                    {leadUser ? <UserCard user={eaLeadUser} /> : "..."}
                  </AboutField>
                  <AboutField label="product line lead" gridSizes={{ xs: 6 }}>
                    {leadUser ? <UserCard user={leadUser} /> : "..."}
                  </AboutField>
                  <AboutField label="product line co-lead" gridSizes={{ xs: 6 }}>
                    {coleadUser ? <UserCard user={coleadUser} /> : "..."}
                  </AboutField>
                  <AboutField label="delivery lead" gridSizes={{ xs: 6 }}>
                    {deliveryLeadUser ? <UserCard user={deliveryLeadUser} /> : "..."}
                  </AboutField>
                  <AboutField label="engineering lead" gridSizes={{ xs: 6 }}>
                    {engineeringLeadUser ? <UserCard user={engineeringLeadUser} /> : "..."}
                  </AboutField>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <FeatureFlagged with="design-integrity">
            <Grid item md={6} xs={12}>
              <DesignIntegrityStatsCard variant="gridItem" />
            </Grid>
          </FeatureFlagged>
          <Grid item md={6}>
            <EntityLinksCard />
          </Grid>
          <Grid item md={12}>
            <EntityHasSystemsCard variant="gridItem" title="Products" />
          </Grid>
        </Grid >
      </EntityLayout.Route >
    </EntityLayout >
  );
};