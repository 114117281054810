import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import React from 'react';

export const ReadmePage = () => {
    return (
        <>
            <Typography variant='h2'>How do I contribute to Helios??</Typography>
            <Typography variant='body1'>Read more about Helios and how to contribute <Link to="https://pandoradigital.atlassian.net/wiki/spaces/IDP/overview" target="_blank">on Confluence</Link>.</Typography>
        </>
    );
};