import { default as React } from 'react';
import { Header, Page, RoutedTabs } from "@backstage/core-components";
import { ReadmePage } from './Readme';
import { ContributorsPage } from './Contributors';
import { ReleaseNotesPage } from './ReleaseNotes';

export const ContributePage = () => {
  return (
    <Page themeId="home">
      <Header
        title='Contribute to Helios'
        subtitle='Do the right thing - take ownership of Helios and contribute with code or data changes'
      />
      <RoutedTabs routes={[
        { title: "How to contribute", path: "readme", children: (<ReadmePage />) },
        { title: "Contributors", path: "contributors", children: (<ContributorsPage />) },
        { title: "Release Notes", path: "release-notes", children: (<ReleaseNotesPage />) },
        // { title: "API Graph", path: "graph", children: (<ApiGraphPage />) },
      ]} />
    </Page>
  );
};


