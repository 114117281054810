import { default as React } from 'react';
import { Header, Page, RoutedTabs } from "@backstage/core-components";
import { CustomApiExplorerPage } from './CustomApiExplorerPage';
import { ApiGraphPage } from './ApiGraphPage';

export const ApiPage = () => {
  return (
    <Page themeId="home">
      <Header
        title='Explore our APIs'
        subtitle='Discover solutions available in our ecosystem'
      />
      <RoutedTabs routes={[
        { title: "API Explorer", path: "explore", children: (<CustomApiExplorerPage />) },
        { title: "API Graph", path: "graph", children: (<ApiGraphPage />) },
      ]} />
    </Page>
  );
};


