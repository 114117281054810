import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  identityApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';

import { PandoraTechRadarApi } from './components/tech-radar/PandoraTechRadar';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';

import {
  VisitsStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';

import { NewRelicBrowser } from '@backstage-community/plugin-analytics-module-newrelic-browser';

export const apis: AnyApiFactory[] = [
    createApiFactory(techRadarApiRef, new PandoraTechRadarApi()),
    
    createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),

  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),

  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      NewRelicBrowser.fromConfig(configApi, {
        identityApi,
      }),
  }),
];
