import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import { InfoCardVariants, TableColumn } from '@backstage/core-components';
import React from 'react';
import {
  RelatedEntitiesCard,
} from '@backstage/plugin-catalog';
import { EaPackDomainEntityV1beta1 } from '@internal/plugin-ea-pack-common';
import { EntityTable } from '@backstage/plugin-catalog-react';



const systemEntityColumns: TableColumn<EaPackDomainEntityV1beta1>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: "Domain" }),
  { title: "Type", field: "spec.type" },
  EntityTable.columns.createEntityRelationColumn({ title: "Lead", relation: "leadBy", defaultKind: "user", filter: { kind: "user" } }),
  EntityTable.columns.createEntityRelationColumn({ title: "Co-Lead", relation: "coleadBy", defaultKind: "user", filter: { kind: "user" } }),
  EntityTable.columns.createEntityRelationColumn({ title: "Delivery Lead", relation: "deliveryLeadOf", defaultKind: "user", filter: { kind: "user" } }),
  EntityTable.columns.createEntityRelationColumn({ title: "Engineering Lead", relation: "engineeringLeadOf", defaultKind: "user", filter: { kind: "user" } }),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
const systemEntityHelpLink: string =
  'https://backstage.io/docs/features/software-catalog/descriptor-format#kind-system';
const asSystemEntities = (entities: Entity[]): EaPackDomainEntityV1beta1[] =>
  entities as EaPackDomainEntityV1beta1[];


/** @public */
export interface HasProductLinesCardProps {
  variant?: InfoCardVariants;
  title?: string;
}

export function HasProductLinesCard(props: HasProductLinesCardProps) {
  const { variant = 'gridItem', title = 'Product Lines & Sub-Domains' } = props;
  return (
    <RelatedEntitiesCard
      variant={variant}
      title={title}
      entityKind={"Domain"}
      relationType={RELATION_HAS_PART}
      columns={systemEntityColumns}
      asRenderableEntities={asSystemEntities}
      emptyMessage="No Product Lines or Sub Domains are part of this domain"
      emptyHelpLink={systemEntityHelpLink}
    />
  );
}