import { Typography } from '@material-ui/core';
import React from 'react';

export const ReleaseNotesPage = () => {
    return (
        <>
            <Typography variant='h2'>Release notes</Typography>
            <Typography variant='body1'>Release notes will show up here</Typography>
        </>
    );
};