import { default as React, useEffect, useState } from 'react';
import { Header, Page, Progress, RoutedTabs } from "@backstage/core-components";
import { ExploreDomainPage } from './ExploreDomainPage';
import { ValueStreamPage } from './ValueStreamPage';
import { SystemEntityV1alpha1 } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { EaPackDomainEntityV1beta1 } from '@internal/plugin-ea-pack-common';


export interface DomainEntityWithSubDomains {
  domain: EaPackDomainEntityV1beta1
  subDomains: SubDomainEntityWithSystems[]
  products: SystemEntityV1alpha1[]
}
export interface SubDomainEntityWithSystems {
  subDomain: EaPackDomainEntityV1beta1
  products: SystemEntityV1alpha1[]
}



export const ExploreFooPage = () => {
  return (<div>hahaha</div>
  );
};
export const ExplorePage = () => {

  const catalogApi = useApi(catalogApiRef);
  const [domains, setDomains] = useState<DomainEntityWithSubDomains[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const [domainResponse, systemsResponse] = await Promise.all([
        catalogApi.getEntities({ filter: { kind: "domain" } }),
        catalogApi.queryEntities({ filter: { kind: "System" }, limit: 1000 })
      ]);

      const allDomains = domainResponse.items.map(i => i as EaPackDomainEntityV1beta1);
      const allSystems = systemsResponse.items.map(i => i as SystemEntityV1alpha1);
      const topDomains = allDomains.filter(d => d.spec.type === "Domain")
        .map(d => {
          const dom = {
            domain: d,
            subDomains: allDomains.filter(i => i.spec.subdomainOf == d.metadata.name)
              .sort((a, b) => a.metadata.title!.localeCompare(b.metadata.title!))
              .map(sd => {
                return {
                  subDomain: sd,
                  products: allSystems.filter(p => p.spec.domain == sd.metadata.name)
                } as SubDomainEntityWithSystems
              })
          } as DomainEntityWithSubDomains;
          dom.products = dom.subDomains.flatMap(sd => sd.products);
          return dom;
        })
        .sort((a, b) => a.domain.metadata.title!.localeCompare(b.domain.metadata.title!));
      setDomains(topDomains);
    })();
  }, [catalogApi]);

  if (domains === undefined) return (<Progress />);

  return (
    <Page themeId="home">
      <Header
        title='Explore our ecosystem'
        subtitle='Discover solutions available in our ecosystem'
      />
      <RoutedTabs routes={[
        { title: "Domains", path: "domains", children: (<ExploreDomainPage data={domains}/>) },
        // { title: "Value Stream", path: "value-stream", children: (<ValueStreamPage data={domains}/>) },
        // { title: "Teams", path: "teams", children: (<ExploreFooPage />) },
        // { title: "Architecture Toolbox", path: "toolbox", children: (<ExploreFooPage />) },
      ]} />
    </Page>
  );
};


