import { Entity, RELATION_API_CONSUMED_BY, RELATION_API_PROVIDED_BY, RELATION_CONSUMES_API, RELATION_HAS_PART, RELATION_PART_OF, RELATION_PROVIDES_API, SystemEntityV1alpha1, getCompoundEntityRef, parseEntityRef } from '@backstage/catalog-model';
import { useAnalytics, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { ALL_RELATION_PAIRS, EntityNode, EntityRelationsGraph, Direction } from '@backstage/plugin-catalog-graph';
import {
    catalogApiRef,
    entityRouteRef,
    humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiGraphCustomNodeRenderer } from './ApiGraphCustomNodeRenderer';
import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, Collapse, Checkbox, ListItemSecondaryAction } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import SubDomainIcon from '@material-ui/icons/ViewModule';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Progress } from '@backstage/core-components';
import { EaPackDomainEntityV1beta1 } from '@internal/plugin-ea-pack-common';
import { DomainEntityWithSubDomains, SubDomainEntityWithSystems } from '../ExplorePage';
const useStyles = makeStyles(
    theme => ({
        content: {
            minHeight: 0,
        },
        container: {
            height: '100%',
            maxHeight: '100%',
            minHeight: 0,
        },
        fullHeight: {
            maxHeight: '100%',
            display: 'flex',
            minHeight: 0,
        },
        graphWrapper: {
            position: 'relative',
            flex: 1,
            minHeight: 0,
            display: 'flex',
        },
        graph: {
            flex: 1,
            minHeight: 0,
        },
        icon: {
            minWidth: 32
        },
        legend: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: theme.spacing(1),
            '& .icon': {
                verticalAlign: 'bottom',
            },
        },
        nested: {
            paddingLeft: theme.spacing(1),
        },
        nested2: {
            paddingLeft: theme.spacing(2),
        },
    }),
    { name: 'PluginCatalogGraphCatalogGraphPage' },
);


export const ApiGraphPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const catalogEntityRoute = useRouteRef(entityRouteRef);
    const analytics = useAnalytics();
    const onNodeClick = useCallback(
        (node: EntityNode, event: MouseEvent<unknown>) => {
            const nodeEntityName = parseEntityRef(node.id);

            if (event.shiftKey) {
                const path = catalogEntityRoute({
                    kind: nodeEntityName.kind.toLocaleLowerCase('en-US'),
                    namespace: nodeEntityName.namespace.toLocaleLowerCase('en-US'),
                    name: nodeEntityName.name,
                });

                analytics.captureEvent(
                    'click',
                    node.entity.metadata.title ?? humanizeEntityRef(nodeEntityName),
                    { attributes: { to: path } },
                );
                navigate(path);
            } else {
                analytics.captureEvent(
                    'click',
                    node.entity.metadata.title ?? humanizeEntityRef(nodeEntityName),
                );
            }
        },
        [catalogEntityRoute, navigate, analytics],
    );

    const [open, setOpen] = React.useState({} as { [key: string]: boolean });


    const makeHandleOnClickItem = (key: string) => (event: React.MouseEvent<HTMLDivElement>) => {
        open[key] = !open[key];
        setOpen({ ...open });
    };

    const catalogApi = useApi(catalogApiRef);
    const [domains, setDomains] = useState<DomainEntityWithSubDomains[] | undefined>(undefined);
    const [selectedProducts, setSelectedProducts] = useState<SystemEntityV1alpha1[]>([]);

    useEffect(() => {
        (async () => {
            const [domainResponse, systemsResponse] = await Promise.all([
                catalogApi.getEntities({ filter: { kind: "domain" } }),
                catalogApi.queryEntities({ filter: { kind: "System" }, limit: 1000 })
            ]);

            const allDomains = domainResponse.items.map(i => i as EaPackDomainEntityV1beta1);
            const allSystems = systemsResponse.items.map(i => i as SystemEntityV1alpha1);
            const topDomains = allDomains.filter(d => d.spec.type === "Domain")
                .map(d => {
                    const dom = {
                        domain: d,
                        subDomains: allDomains.filter(i => i.spec.subdomainOf == d.metadata.name)
                            .sort((a, b) => a.metadata.title!.localeCompare(b.metadata.title!))
                            .map(sd => {
                                return {
                                    subDomain: sd,
                                    products: allSystems.filter(p => p.spec.domain == sd.metadata.name)
                                } as SubDomainEntityWithSystems
                            })
                    } as DomainEntityWithSubDomains;
                    dom.products = dom.subDomains.flatMap(sd => sd.products);
                    return dom;
                })
                .sort((a, b) => a.domain.metadata.title!.localeCompare(b.domain.metadata.title!));
            setDomains(topDomains);

            const openState = {} as { [key: string]: boolean };
            topDomains.forEach(d => openState[`d-${d.domain.metadata.name}`] = false);
            setOpen(openState);
        })();
    }, [catalogApi]);

    if (domains === undefined) return (<Progress />);

    const handleProductToggle = (product: SystemEntityV1alpha1) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked && !selectedProducts.includes(product)) {
            setSelectedProducts([...selectedProducts, product]);
        }
        if (!checked && selectedProducts.includes(product)) {
            setSelectedProducts([...selectedProducts.filter(p => p !== product)]);
        }
    };

    const entityFilter = (entity: Entity): boolean => {
        //Filter out SubDomain/ProductLines that doesn't have one or more Products selected
        if (entity.kind === "Domain" && (entity as EaPackDomainEntityV1beta1).spec.type != "Domain") {
            return selectedProducts.filter(p => p.spec.domain === entity.metadata.name).length > 0;
        }

        //Filter out Systems that are not in the list
        if (entity.kind === "System" ){
            return selectedProducts.filter(p => p.metadata.name === entity.metadata.name).length > 0;
        }

        return true;
    }

    return (
        <Grid container className={classes.fullHeight}>
            <Grid item xs={2} className={classes.fullHeight}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader" disableGutters>
                            Domains
                        </ListSubheader>
                    }
                >
                    {domains.map(d => (<div key={`domain-list:${d.domain.metadata.name}`}>
                        <ListItem disableGutters button onClick={makeHandleOnClickItem(`d-${d.domain.metadata.name}`)}>
                            <ListItemIcon className={classes.icon}>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary={d.domain.metadata.title} />
                            {open[`d-${d.domain.metadata.name}`] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open[`d-${d.domain.metadata.name}`]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {d.subDomains.map(sd => (<div key={sd.subDomain.metadata.name}>
                                    <ListItem disableGutters button className={classes.nested} onClick={makeHandleOnClickItem(`sd-${sd.subDomain.metadata.name}`)}>
                                        <ListItemIcon className={classes.icon}>
                                            <SubDomainIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={sd.subDomain.metadata.title} />
                                        {open[`sd-${sd.subDomain.metadata.name}`] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={open[`sd-${sd.subDomain.metadata.name}`]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {sd.products.length > 0 ? sd.products.map(p => (<ListItem button className={classes.nested2} key={p.metadata.name}>
                                                {/* <ListItemIcon className={classes.icon}>
                                                    <BusinessIcon />
                                                </ListItemIcon> */}
                                                <ListItemText primary={p.metadata.title} />
                                                <ListItemSecondaryAction>
                                                    <Checkbox edge="end" onChange={handleProductToggle(p)} />
                                                </ListItemSecondaryAction>
                                            </ListItem>)):
                                            <ListItem  className={classes.nested2}>No Products</ListItem>
                                            }
                                        </List>
                                    </Collapse>
                                </div>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                    ))}
                </List>
            </Grid>
            <Grid item xs={10} className={classes.fullHeight}>
                <Paper className={classes.graphWrapper}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        display="block"
                        className={classes.legend}
                    >
                        <ZoomOutMap className="icon" /> Use pinch &amp; zoom to move
                        around the diagram. Click to change active node, shift click to
                        navigate to entity.
                    </Typography>
                    <EntityRelationsGraph
                        // rootEntityNames={parseEntityRef({ name: "b2b-sales-order-events", kind: "api", namespace: "default" })}
                        rootEntityNames={selectedProducts.map(getCompoundEntityRef)}
                        maxDepth={2}
                        kinds={["api", "system", "component", "domain"]}
                        relations={[
                            RELATION_CONSUMES_API,
                            RELATION_PROVIDES_API,
                            //RELATION_API_CONSUMED_BY,
                            //RELATION_API_PROVIDED_BY,
                            RELATION_PART_OF,
                            RELATION_HAS_PART
                        ]}
                        mergeRelations={true}
                        unidirectional={false}
                        onNodeClick={onNodeClick}
                        direction={Direction.TOP_BOTTOM}
                        relationPairs={ALL_RELATION_PAIRS}
                        entityFilter={entityFilter}
                        //className={classes.graph}
                        zoom="enabled"
                        curve={"curveMonotoneX"}
                        showArrowHeads={true}
                        renderNode={ApiGraphCustomNodeRenderer}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};