import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import {
  createTheme,
  createUnifiedThemeFromV4,
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  UnifiedTheme,
} from '@backstage/theme';

import { alpha, Theme } from '@material-ui/core/styles';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';

import './fonts.css'


const textColor = "#27251f"


export const PandoraPink = "#FFCAD4";
export const PandoraBlack = "#27251F";
export const PandoraGray = "#B7B8B9";
export const PandoraRed = "#F97C89";
export const PandoraOrange = "#FEAD77";
export const PandoraYellow = "#F5E1A4";
export const PandoraGreen = "#9EB356";
export const PandoraBlue = "#58A7AF";
export const PandoraPurple = "#635C8A";


// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: textColor,
  };
});

// TODO(awanlin): Continuing to use the deprecated `createTheme` for now
// will come back to clean this up when we have a better solution for this

// eslint-disable-next-line
const baseTheme = createTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: textColor,
        light: '#4C9AFF',
        dark: textColor,
      },
      secondary: {
        main: '#FF5630',
        light: '#FFAB00',
        dark: '#6554C0',
      },
      grey: {
        50: '#C1C7D0',
        100: '#7A869A',
        200: '#6B778C',
        300: '#5E6C84',
        400: '#505F79',
        500: '#42526E',
        600: '#344563',
        700: '#253858',
        800: '#172B4D',
        900: '#091E42',
      },
      error: {
        main: '#FF5630',
        light: '#FF8F73',
        dark: '#DE350B',
      },
      warning: {
        main: '#FFAB00',
        light: '#FFE380',
        dark: '#FF8B00',
      },
      success: {
        main: '#36B37E',
        light: '#79F2C0',
        dark: '#006644',
      },
      info: {
        main: '#0065FF',
        light: '#4C9AFF',
        dark: '#0747A6',
      },
      navigation: {
        ...palettes.light.navigation,
        background: PandoraPink,
        color: textColor,
        indicator: '#2684FF',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)',
        },
      },
      text: {
        primary: textColor,
      },
      background: {
        default: '#FFFFFF',
      },
    },
  }),
  fontFamily: 'Pandora Text',
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  typography: {
    fontFamily: 'Pandora Text',
    htmlFontSize: 14,
    h1: {
      fontSize: 46,
      fontWeight: 500,
      marginBottom: 4
    },
    h2: {
      fontSize: 32,
      fontWeight: 500,
      marginBottom: 4
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: 4
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 4
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 4
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 4
    },
  }
});

const createCustomThemeOverrides = (
  theme: Theme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        paddingBottom: theme.spacing(1),
        backgroundColor: "#FFF"
      },
      title: {
        color: textColor,
        fontWeight: 900,
        fontSize: "48px",
        textTransform: "uppercase",
      },
      subtitle: {
        color: textColor,
        backgroundColor: PandoraPink
      },
      type: {
        color: alpha(theme.page.fontColor, 0.8),
      },

    },
    BackstageSidebarItem: {
      root: {
        fontFamily: "Pandora Display",
        textTransform: "uppercase",
        color: textColor

      },
      secondaryAction: {
        display: 'none'
      }
    },
    /*
    // TODO: Remove after https://github.com/backstage/backstage/pull/16853 is available here
    BackstageHeaderLabel: {
      label: {
        color: theme.page.fontColor,
      },
      value: {
        color: alpha(theme.page.fontColor, 0.8),
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none',
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
      },
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
      standardError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardInfo: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardSuccess: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.success.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardWarning: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.secondary.light,
        '& $icon': {
          color: theme.palette.grey[700],
        },
      },
    },
    MuiAutocomplete: {
      root: {
        '&[aria-expanded=true]': {
          backgroundColor: '#26385A',
          color: '#FFFFFF',
        },
        '&[aria-expanded=true] path': {
          fill: '#FFFFFF',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(9,30,69,0.54)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
     MuiSelect: {
      root: {
        '&[aria-expanded]': {
          backgroundColor: '#26385A',
          color: '#FFFFFF',
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 10,
      },
      switchBase: {
        padding: 12,
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14,
      },
      track: {
        borderRadius: 9,
      },
    },
    MuiTabs: {
      indicator: {
        transition: 'none',
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
    },*/
    MuiLink: {
      underlineHover: {
        fontWeight: 600
      }
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        color: textColor,
        margin: 4,
        backgroundColor: PandoraPink,
      },
      outlined: {
        backgroundColor: PandoraPink,
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Pandora Text"
      }
    },
    MuiTypography: {
      root: { fontFamily: "Pandora Text", fontSize: 14, fontWeight: 400 },
      h1: { fontFamily: "Pandora Display", fontSize: 46, fontWeight: 500 },
      h2: { fontFamily: "Pandora Display", fontSize: 32, fontWeight: 500 },
      h3: { fontFamily: "Pandora Display", fontSize: 24, fontWeight: 500 },
      h4: { fontFamily: "Pandora Display", fontSize: 20, fontWeight: 500 },
      h5: { fontFamily: "Pandora Display", fontSize: 18, fontWeight: 500 },
      h6: { fontFamily: "Pandora Display", fontSize: 16, fontWeight: 500 },
      body1: { fontFamily: "Pandora Text", fontSize: 14, fontWeight: 400 },
      body2: { fontFamily: "Pandora Text", fontSize: 14, fontWeight: 400 },
      subtitle1: { fontFamily: "Pandora Display", fontSize: 20, fontWeight: 500 },
      subtitle2: { fontFamily: "Pandora Display", fontSize: 16, fontWeight: 500 },
    },
    BackstageItemCardHeader: {
      root: {
        backgroundImage: "",
        backgroundColor: PandoraPink,
        color: textColor
      }
    },
    BackstageSignInPage: {
      container: {
        position: 'absolute',
        top: '20%',
        transform: 'translateY(-20%)'
      }
    },
    BackstagePage: {
      root: {
        // Really hacky solution to applying a style to the top page element only when the signin form is showing.
        // We don't have a good way of identifying this state as there are no IDs on the elements and classes are renamed when compiled in React
        '&:has(article > ul > li > div > div > button > span:not(:empty))': {
          backgroundImage: 'url("/signin_bg.jpg")',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative'

        },
        '&:has(article > ul > li > div > div > button > span:not(:empty)) header': {
          display: "none"
        }
      }
    }
  };
};

export const pandoraTheme: UnifiedTheme = createUnifiedThemeFromV4({
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),

  },
});

/*
:root {
    --blue: #27251f;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #c00;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #008827;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #ffcad4;
    --secondary: #27251f;
    --success: #00823a;
    --info: #1979ff;
    --warning: #f79e13;
    --danger: #d91f46;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 544px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 1025px;
    --breakpoint-xl: 1200px;
}
*/