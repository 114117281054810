import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Paper, Typography } from '@material-ui/core';
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
} from '@backstage/core-components';
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  EntityRefLink,
} from '@backstage/plugin-catalog-react';
import { BusinessCapabilityEntityV1beta1KindName, BusinessCapabilityEntityV1beta1 } from "../../../../ea-pack-common";

interface BusinessCapabilityWithChildren extends BusinessCapabilityEntityV1beta1 {
  children?: BusinessCapabilityWithChildren[]
}


export interface ExampleComponentProps {
  message?: string;
}

export function ExampleComponent(props: ExampleComponentProps) {
  // By destructuring props here rather than in the signature the API docs will look nicer

  const [entityTree, setEntityTree] = useState<BusinessCapabilityWithChildren[]>([])

  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const fetchData = async () => {
      const x = await catalogApi.getEntities({
        filter: [{ kind: [BusinessCapabilityEntityV1beta1KindName] }],
        //fields: ['name', 'metadata.title', 'metadata.name', 'spec.level', 'spec.parent', 'spec.color', 'relations']
      });
      const items = x.items as BusinessCapabilityWithChildren[];

      function mapRecursive(nodes: BusinessCapabilityWithChildren[], all: BusinessCapabilityWithChildren[]) {
        for (const node of nodes) {
          node.children = all.filter(i => i.spec.parent == node.metadata.name).sort(businessCapabilitySorter);
          mapRecursive(node.children, all);
        }
      }

      const l1 = items.filter(i => i.spec.level == 1).sort(businessCapabilitySorter);;
      mapRecursive(l1, items);
      setEntityTree(l1);
    };

    fetchData().catch(console.error);;
  }, []);

  function businessCapabilitySorter(a: BusinessCapabilityWithChildren, b: BusinessCapabilityWithChildren): number {
    if (a.spec.order != null && b.spec.order != null) {
      return a.spec.order - b.spec.order;
    }
    else {
      return (a.metadata?.title ?? "").localeCompare(b.metadata?.title ?? "");
    }
  }


  const RenderL1BusinessCapability = (l1: BusinessCapabilityWithChildren) => {
    return <Paper style={{ padding: "8px", backgroundColor: l1.spec.color }}>
      <EntityRefLink entityRef={l1}><Typography variant='h4' gutterBottom={true}>{l1.metadata.title}</Typography></EntityRefLink>
      <Grid
        container
        direction="column"
      >
        {l1.children && l1.children.length > 0 ? l1.children.map((l2, i) => <Grid item xs={12}>
          <RenderL2BusinessCapability key={i} {...l2} />
        </Grid>) : <Typography variant='body1'>No L2 Capabilities</Typography>}

      </Grid>

    </Paper>

  }
  const RenderL2BusinessCapability = (l2: BusinessCapabilityWithChildren) => {
    return <Paper style={{ padding: "8px" }}>
      <EntityRefLink entityRef={l2}><Typography variant='h6'>{l2.metadata.title}</Typography></EntityRefLink>
      <List dense={true}>
        {l2.children && l2.children.length > 0 ? l2.children.map((l3, i) => <ListItem key={i}>
          <EntityRefLink entityRef={l3} title={`${l3.metadata.title} (${l3.relations?.filter(i => i.targetRef.indexOf("system") >= 0).length ?? 0})`} />
        </ListItem>) : <Typography variant='body1'>No L3 Business Capabilities</Typography>}
      </List>
    </Paper>

  }

  console.trace("BC Tree", entityTree);

  return <PageWithHeader title={`Business Capability Map`} themeId="home">
    <Content>
      <ContentHeader title="Dive into L1-3 business capabilities. Click on a capability to learn more">
        <SupportButton>All your software catalog entities</SupportButton>
      </ContentHeader>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {entityTree.map((l1, i) => <Grid item key={i} xs={2}>
          <RenderL1BusinessCapability {...l1} />
        </Grid>)}
      </Grid>
    </Content>
  </PageWithHeader>
    ;
}
