import { parseEntityRef, ResourceEntity, systemEntityV1alpha1Validator } from "@backstage/catalog-model";
import { TableColumn } from "@backstage/core-components";
import { CatalogTable, CatalogTableColumnsFunc, CatalogTableRow } from "@backstage/plugin-catalog";
import { EntityListContextProps, EntityRefLink } from "@backstage/plugin-catalog-react";
import { AdoResourceEntityV1beta1, BusinessCapabilityEntityV1beta1, BusinessCapabilityEntityV1beta1KindName, EaPackDomainEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { Chip } from "@material-ui/core";
import React from "react";



export const EntityColumCustomizer: CatalogTableColumnsFunc = (entityListContext) => {
  switch (entityListContext.filters.kind?.value) {
    case BusinessCapabilityEntityV1beta1KindName.toLowerCase():
      return [
        CatalogTable.columns.createNameColumn(),
        createBusinessCapabilityParentColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        CatalogTable.columns.createOwnerColumn()
      ];
    case "domain":
      return [
        CatalogTable.columns.createNameColumn(),
        createSubDomainParentColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        createSubDomainLeadColumn("lead"),
        createSubDomainLeadColumn("deliveryLead"),
        createSubDomainLeadColumn("engineeringLead")
      ];
    case "resource":
      if (Array.isArray(entityListContext.filters.type?.value) && entityListContext.filters.type!.value[0].toLowerCase() === "ado repository") {
        return [
          CatalogTable.columns.createNameColumn(),
          createAdoProjectParentColumn(),
          createProductColumn(),
          CatalogTable.columns.createOwnerColumn(),
          createRepoLanguageStatsColumn()
        ];
      }
      if (Array.isArray(entityListContext.filters.type?.value) && entityListContext.filters.type!.value[0].toLowerCase() === "ado project") {
        return [
          CatalogTable.columns.createNameColumn(),
          createProductColumn(),
          CatalogTable.columns.createOwnerColumn(),
          createRepoLanguageStatsColumn()
        ];
      }
      return createDefaultColumns(entityListContext);
    default:
      return createDefaultColumns(entityListContext);
  }
}

function createDefaultColumns(entityListContext: EntityListContextProps) {
  const list = CatalogTable.defaultColumnsFunc(entityListContext);
  const systemCol = list.find(c => c.title === "System");
  if (systemCol) systemCol.title = "Product";
  return list;
}

function createProductColumn() {
  const col = CatalogTable.columns.createSystemColumn();
  col.title = "Product";
  return col;
}

function createBusinessCapabilityParentColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Parent',
    render: ({ entity }) => {
      const businessCap = entity as BusinessCapabilityEntityV1beta1;
      if (businessCap.spec.parent == null) return "";

      const parentRef = parseEntityRef({
        name: businessCap.spec.parent,
        kind: BusinessCapabilityEntityV1beta1KindName
      })

      return (<EntityRefLink entityRef={parentRef} />)
    },
  };
}
function createSubDomainParentColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Domain',
    render: ({ entity }) => {
      const subDomain = entity as EaPackDomainEntityV1beta1;
      if (subDomain.spec.subdomainOf == null) return "";

      const parentRef = parseEntityRef(`domain:default/${subDomain.spec.subdomainOf}`);

      return (<EntityRefLink entityRef={parentRef} />)
    },
  };
}
function createSubDomainLeadColumn(role: "lead" | "coLead" | "deliveryLead" | "engineeringLead" | "eaLead"): TableColumn<CatalogTableRow> {
  return {
    title: role == "lead" ? "Lead" : role == "coLead" ? "Co-lead" : role == "deliveryLead" ? "Delivery Lead" : role == "engineeringLead" ? "Engineering Lead" : role == "eaLead" ? "EA Lead" : "",
    render: ({ entity }) => {
      const subDomain = entity as EaPackDomainEntityV1beta1;
      if (subDomain.spec.leads == null || subDomain.spec.leads[role] == null) return "";
      const roleRef = parseEntityRef(`user:default/${subDomain.spec.leads[role]!}`);
      return (<EntityRefLink entityRef={roleRef} />)
    },
  };
}
function createAdoProjectParentColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Azure Devops Project',
    render: ({ entity }) => {
      const repo = entity as ResourceEntity;
      const adoProjectRef = repo.relations?.find(r => r.type === "partOf" && r.targetRef.startsWith("resource:default/ado-project-"));
      if (!adoProjectRef) return "";
      return (<EntityRefLink entityRef={adoProjectRef.targetRef} />)
    },
  };
}
function createRepoLanguageStatsColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Language Breakdown',
    field: 'entity.spec.languageBreakdown',
    cellStyle: {
      padding: '0px 16px 0px 20px',
    },
    render: ({ entity }) => {
      const ado = entity as AdoResourceEntityV1beta1;
      if(!ado.spec.languageBreakdown) return <></>;

      const top5 = ado.spec.languageBreakdown.sort((a,b) => (b.languagePercentage || 0) - (a.languagePercentage || 0))
                                             .slice(0,5)
                                             .filter(l => l.languagePercentage && l.languagePercentage>= 10);
      return (
      <>
        {top5.map(t => (
            <Chip
              key={t.name}
              label={`${t.name}: ${t.languagePercentage||0}%`}
              size="small"
              variant="outlined"
              style={{ marginBottom: '0px' }}
            />
          ))}
      </>
    )},
    width: 'auto',
  };
}