import { Chip, Grid, List, ListItem, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/frontend-plugin-api';
import { ContributionEntityV1beta1 } from '@internal/plugin-ea-pack-common';
import { UserEntity } from '@backstage/catalog-model';
import { Avatar, Link } from '@backstage/core-components';
import FaceIcon from '@material-ui/icons/Face';
import CodeIcon from '@material-ui/icons/Code';
import NoteAddIcon from '@material-ui/icons/NoteAdd';


interface ContributorType {
    key: string;
    user?: UserEntity;
    codeCount: number;
    dataCount: number;
    justification?: string;
}

const UserCard = (props: { c: ContributorType }) => {
    const { c } = props;
    return (<Paper elevation={1} style={{ padding: "16px", width: "100%" }}><Grid container>
        <Grid item><Avatar displayName={c.user ? c.user.spec.profile?.displayName : c.key} picture={c.user?.spec.profile?.picture} /></Grid>
        <Grid item>
            {c.user ? <Link to={`/catalog/${c.user.metadata.namespace}/user/${c.user.metadata.name}`}><Typography variant='h4'>{c.user.spec.profile?.displayName}</Typography></Link> : <Typography variant='h4'>{c.key}</Typography>}
            <Chip
                size="small"
                label={`Code contributions: ${c.codeCount}`}
                icon={<CodeIcon />}
            />
            {c.justification === undefined && <Chip
                size="small"
                label={`Data contributions: ${c.dataCount}`}
                icon={<NoteAddIcon />}
            />
            }
            {c.justification !== undefined && <Tooltip title={c.justification} arrow><Chip
                size="small"
                label={`Data contributions: ${c.dataCount}`}
                icon={<FaceIcon />}
            />
            </Tooltip>}
        </Grid>
    </Grid>
    </Paper>)
}



export const ContributorsPage = () => {
    const catalogApi = useApi(catalogApiRef);
    const [contributors, setContributors] = useState<ContributorType[] | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const contribResponse = (await catalogApi.getEntities({ filter: { kind: "contribution" } })).items.map(i => i as ContributionEntityV1beta1);

            const allUniqueUsers = new Set(contribResponse
                .flatMap(c => {
                    let list = c.spec.contributions.automatic.map(x => x.contributor);
                    if (c.spec.contributions.manual) list = [...list, ...c.spec.contributions.manual.map(x => x.contributor)];
                    return list;
                }));
            console.log(allUniqueUsers)
            const allContributors: { [key: string]: ContributorType } = {};
            for (const user of allUniqueUsers) {
                if (user.endsWith("_pandora.net"))
                    allContributors[user] = { key: user, codeCount: 0, dataCount: 0 };
            }
            console.log(allContributors)

            const allUserEntities = (await catalogApi.getEntitiesByRefs({ entityRefs: Array.from(allUniqueUsers) })).items.map(i => i as UserEntity);

            for (const contribType of contribResponse) {
                for (const c of [...contribType.spec.contributions.automatic, ...contribType.spec.contributions.manual ? contribType.spec.contributions.manual : []]) {
                    if(allContributors[c.contributor] === undefined) continue;

                    const userName = c.contributor.startsWith("user:default/") ? c.contributor.substring(13) : c.contributor;
                    const user = allUserEntities.find(u => u?.metadata.name === userName);

                    allContributors[c.contributor].user = user;
                    if (c.justification) allContributors[c.contributor].justification = c.justification;
                    if (contribType.metadata.name === "code") allContributors[c.contributor].codeCount += c.count;
                    if (contribType.metadata.name === "data") allContributors[c.contributor].dataCount += c.count;
                }
            }
            setContributors(Object.entries(allContributors).map(x => x[1]).sort((a, b) => (b.codeCount + b.dataCount) - (a.codeCount + a.dataCount)));
        })();
    }, [catalogApi]);


    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h2'>Who are the awesome Pandorians behind Helios?</Typography>
                <Typography variant='body1'>Helios is a community effort delivered by many great Pandorians in Digital &amp; Technology.
                    The lists below are automatically generated based on commits to the code base and manually entered information.
                </Typography>
                <Typography variant='h4'>Code contributors</Typography>
                <Typography variant='body1'>Code contributors have contributed one or more times with code to the <Link to='https://pandora-jewelry.visualstudio.com/Helios/_git/Helios' target='_blank'>Helios Code Repo</Link>.
                    Contribute today and get on the list!
                </Typography>
                <Typography variant='h4'>Data contributors</Typography>
                <Typography variant='body1'>Code contributors have contributed one or more times with data maintainence to the <Link to='https://pandora-jewelry.visualstudio.com/Helios/_git/data' target='_blank'>Helios Data Repo</Link>.
                    Contribute today and get on the list!
                </Typography>
                <Typography variant='h2'>Contributors</Typography>
            </Grid>

            {contributors && contributors.map(c => <Grid item lg={4} md={6} xs={12} key={c.key}><UserCard c={c} /></Grid>)}

        </Grid>
    );
};